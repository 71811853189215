import React from 'react'
import Wolflayout from './wolflayout'
import Helmet from 'react-helmet'
import { cabaretMeta } from '../data/cabaret-data'

const CabaretLayout = ({ children }) => {
  return (
    <Wolflayout bodyClass={'cabaret-body'} isCabaret={true}>
        <div>
          <Helmet>
              <title>{cabaretMeta.title}</title>
              <meta name="description" content={cabaretMeta.description} />
              <meta name="keywords" content={cabaretMeta.description} />
              <meta property="og:title" content={cabaretMeta.title} />
              <meta property="og:description" content={cabaretMeta.description} />
              <meta property="og:url" content={cabaretMeta.url} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={cabaretMeta.image} />
          </Helmet>
        </div>
        <main>{children}</main>
    </Wolflayout>
  )
}

export default CabaretLayout
