import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'

const CabaretInfosPorteParole = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

            <CabaretInfosNavigation />

            <h1>Porte-Parole</h1>

            <div className='cabaret-infos-inner'>

                <h2>Sara Dufour</h2>
                <h2>Candidate de l’édition 2014</h2>

                {/* <h3 className='!mt-9'>En performance lors de La Grande Finale du 30 mars 2024</h3> */}

                <h3>Des Francofolies de Montréal jusqu’à Tadoussac, en faisant un tour par Caraquet, Sara Dufour nous présente son univers à travers des chansons qui nous emportent et nous donnent envie de s’évader le temps d’un spectacle. Fière Jeannoise native de Dolbeau-Mistassini, elle passe par l’École Nationale de la chanson de Granby, où elle peaufine son art. Le chemin de Sara Dufour dans le monde de la musique en est un particulier : elle a emprunté plusieurs sentiers sinueux qui la mènent à nous livrer son premier EP en janvier 2015, « Le Breaker ». Guidée par ses passions, elle n’en reste pas là. En 2016 elle lance son tout premier album intitulé «Dépanneur Pierrette». Cet album qui lui permet de faire plus d’une centaine de spectacles à travers la francophonie, et ce, jusqu’en Europe et dans les provinces de l’Est. Elle récolte au passage le prix «chanson de l’année SOCAN» au Gala Country 2017. Au printemps 2019, présente son deuxième album homonyme, et fait plus de 50 spectacles à travers le Québec et le Nouveau-Brunswick. Un franc succès qui positionne Sara dans les palmarès et dans les radios, où elle fait briller sa vision colorée du monde. À l’automne 2019, elle reçoit le prix André “Dédé” Fortin de la fondation SPACQ en tant qu’artiste de la scène émergente.</h3>

                <div className="mt-10 relative h-[35rem]">
                    <iframe
                        title="Sara Dufour - Semi-route Semi-trail"
                        className="absolute top-0 left-0 w-full h-full mb-3rem"
                        src={'https://www.youtube.com/embed/TS-X72lD0no'}
                        allowFullScreen=""
                        referrerPolicy="origin-when-cross-origin"
                        height={'100%'}
                    ></iframe>
                </div>

                <h3>La flamme qui brûle en elle la transporte toujours plus loin et plus vite dans son univers créatif qui est son quotidien. Tout en conservant sa féminité, l’auteure-compositrice-interprète sait livrer avec une énergie peu commune, de fortes émotions dans un langage plutôt franc. Sa sincérité lui donne un charme indéniable. Définitivement « one of the boys », elle présente un country-folk semi route, semi « trail », bon à s’en remonter les manches et se graisser les mains, tant elles taperont au son de sa musique. Sur scène, Sara Dufour est dynamique, authentique et attachante, captivant le public avec ses histoires et ses textes d’escapades colorées. Elle a une fougue et un je ne sais quoi qui la rend absolument unique.</h3>

                <a href="https://www.saradufour.com/" target='_blank' rel="noreferrer">saradufour.com</a>

            </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosPorteParole
